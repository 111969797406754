import React, { useEffect } from 'react'
import { Row, Card, Col, Alert, Button } from "react-bootstrap";

const MyAlert = ({ errorText, setErrorText }) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            setErrorText(null);
        }, 5000);

        return () => clearTimeout(timeout);
    }, [errorText, setErrorText]);
    return (
        <div>
            <Alert variant="danger" dismissible className="solid">
                <strong>Error! </strong> 
                <span style={{marginRight:'20px'}}>{errorText}</span>
                <button className="btn-close"
                    onClick={() => setErrorText(null)}
                    style={{ display:'flex', alignItems:'center' }}
                >
                    <span>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </button>
            </Alert>
        </div>
    )
}

export default MyAlert
