import React from 'react'
import UnderConstruction from '../UnderConstruction'


const CreateIDO = () => {
  return (
    <div>
      <UnderConstruction />
    </div>
  )
}

export default CreateIDO