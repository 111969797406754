import React from 'react';
import './Loading.css'
function Loading() {
  return (
    <div className="loading-container">
      <div className="loading-circle" />
    </div>
  );
}

export default Loading;