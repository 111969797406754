import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { Link, useLocation } from 'react-router-dom';
import { ethers } from "ethers";
import { Col, Table, Card, Button, Modal, Dropdown } from "react-bootstrap";
import './ControlPanel.css'
import zeroTaxABI from './ABI/TokenABI.json';
import contractCreatorABI from './ABI/contractABI.json'
import Loading from "./Loading/Loading";
import { CAList, ABIList } from "./ContractSettings/ContractSettings";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import swal from 'sweetalert';
import MyAlert from "./MyAlert";


export const CanModify = () => {
    return (
        <span style={{ color: 'red' }}>
            *
        </span>
    )
}

const ControlPanel = () => {

    const {
        language,
        defaultAccount,
    } = useContext(ThemeContext);


    //一鍵發幣Proxy地址
    const contractCreator = CAList.bsc.contractCreator;
    const contractRecorder = CAList.bsc.contractRecorder;
    const recorderABI = ABIList.recorderABI;

    const [contractAddress, setContractAddress] = useState(null)
    const [recorderCA, setRecorderCA] = useState(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let tempContractAddress = searchParams.get('contract');

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsLoading(false);
        }, 2500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const [errorText, setErrorText] = useState(null);


    //複製
    const [copied, setCopied] = useState(false);

    //連接錢包
    const [provider, setProvider] = useState(null)
    const [signer, setSigner] = useState(null)
    //各模板
    const [contract, setContract] = useState(null)

    //選擇功能
    const [inFunction, setInFunction] = useState(null);

    //基本資訊
    const [moduleNum, setModuleNum] = useState(0);

    const [name, setName] = useState(null);
    const [symbol, setSymbol] = useState(null);
    const [supply, setSupply] = useState(0);
    const [decimal, setDecimal] = useState(0);

    const [dex, setDex] = useState(null)
    const [currency, setCurrency] = useState(null)
    const [rewardToken, setRewardToken] = useState(null)
    //  所有權
    const [owner, setOwner] = useState(null);

    //  營銷
    const [marketingWallet, setMarketingWallet] = useState(null)
    const [marketingWallet2, setMarketingWallet2] = useState(null)
    const [fundRatio, setFundRatio] = useState(100);

    //  買稅
    const [buyMarketing, setBuyMarketing] = useState(0)
    const [buyLiquidity, setBuyLiquidity] = useState(0)
    const [buyBurn, setBuyBurn] = useState(0)
    const [buyReward, setBuyReward] = useState(0)

    //  賣稅
    const [sellMarketing, setSellMarketing] = useState(0)
    const [sellLiquidity, setSellLiquidity] = useState(0)
    const [sellBurn, setSellBurn] = useState(0)
    const [sellReward, setSellReward] = useState(0)

    //  買賣限制
    const [maxBuyAmount, setMaxBuyAmount] = useState(0)
    const [maxSellAmount, setMaxSellAmount] = useState(0)
    const [maxWalletAmount, setMaxWalletAmount] = useState(0)


    const [isWhitelist, setIsWhitelist] = useState(null)

    const updateEthers = async () => {
        try {
            let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
            setProvider(tempProvider);

            let tempSigner = tempProvider.getSigner();
            setSigner(tempSigner);

            //  代幣合約
            let tempContract = new ethers.Contract(contractAddress, zeroTaxABI, tempSigner)
            setContract(tempContract);

            //  資料合約
            let tempRecorderCA = new ethers.Contract(contractRecorder, recorderABI, tempSigner)
            setRecorderCA(tempRecorderCA);

            let tempList = await tempRecorderCA.contractInfo(contractAddress);
            console.log(tempList);

            setName(tempList.contractName)
            setSymbol(tempList.contractSymbol)

            let realDecimal = ethers.utils.formatUnits(tempList.contractDecimal, "0");
            setDecimal(realDecimal)
            let realSupply = ethers.utils.formatUnits(tempList.contractSupply, "0")
            setSupply(realSupply)
            let realModuleNum = ethers.utils.formatUnits(tempList.contractModule, "0")
            setModuleNum(realModuleNum)

            let tempOwner = await tempContract.owner();
            setOwner(tempOwner);

            if (realModuleNum == 0) return;

            updateContract(tempContract)

            //  買賣限制
            let tempMaxBuy = await tempContract.maxBuyAmount();
            let tempMaxSell = await tempContract.maxSellAmount();
            let tempMaxWallet = await tempContract.maxWalletAmount();
            setMaxBuyAmount(ethers.utils.formatUnits(tempMaxBuy, tempList.contractDecimal))
            setMaxSellAmount(ethers.utils.formatUnits(tempMaxSell, tempList.contractDecimal))
            setMaxWalletAmount(ethers.utils.formatUnits(tempMaxWallet, tempList.contractDecimal))
        } catch (err) {
            console.log(err)
        }
    }

    const updateContract = async (props) => {
        //  營銷錢包
        let tempMarketingWallet = await props.fundAddress();
        let tempMarketingWallet2 = await props.fundAddress2();
        let tempFundRatio = await props.fundRatio();
        setMarketingWallet(tempMarketingWallet);
        setMarketingWallet2(tempMarketingWallet2);
        setFundRatio(bigNumToInt(tempFundRatio))

        //  買入稅
        let tempBuyMarketingFee = await props._buyFundFee();
        let tempBuyLiquidityFee = await props._buyLPFee();
        let tempBuyBurnFee = await props._buyBurnFee();
        setBuyMarketing(bigNumToInt(tempBuyMarketingFee) / 100)
        setBuyLiquidity(bigNumToInt(tempBuyLiquidityFee) / 100)
        setBuyBurn(bigNumToInt(tempBuyBurnFee) / 100)

        //  賣出稅
        let tempSellMarketingFee = await props._sellFundFee();
        let tempSellLiquidityFee = await props._sellLPFee();
        let tempSellBurnFee = await props._sellBurnFee();
        setSellMarketing(bigNumToInt(tempSellMarketingFee) / 100)
        setSellLiquidity(bigNumToInt(tempSellLiquidityFee) / 100)
        setSellBurn(bigNumToInt(tempSellBurnFee) / 100)
    }

    const bigNumToInt = (props) => {
        return ethers.utils.formatUnits(props, "0")
    }

    const getContract = () => {
        setContractAddress(tempContractAddress);
    }

    useEffect(() => {
        if (contractAddress === null)
            //設定合約地址
            getContract()
        if (contractAddress !== null)
            //設定合約地址以後 獲取資料
            updateEthers();
    }, [defaultAccount, contractAddress])


    const [isOpen, setIsOpen] = useState([true, true, true, true, true])
    const [modalWithTooltip, setModalWithTooltip] = useState(false);
    const [modifying, setModifying] = useState([]);

    const nameText = language.value === 'english' ? 'Name' : language.value === 'chinese' ? '代币全称' : '代幣全稱';
    const symbolText = language.value === 'english' ? 'Symbol' : language.value === 'chinese' ? '代币简称' : '代幣簡稱';
    const supplyText = language.value === 'english' ? 'Supply' : language.value === 'chinese' ? '代币总量' : '代幣總量';
    const decimalText = language.value === 'english' ? 'Decimal' : language.value === 'chinese' ? '代币精度' : '代幣精度';
    const moduleText = language.value === 'english' ? 'Module' : language.value === 'chinese' ? '代幣模版' : '代幣模板';

    const basicInfo = [
        {
            title: language.value === 'english'
                ? "Basic Info" : language.value === 'chinese'
                    ? "基本资讯" : "基本資訊",
            bg: "primary",
            description: 'Basic info of the contract, only marketing wallet can be modified',
        },
        {
            title: language.value === 'english'
                ? "Contract Address" : language.value === 'chinese'
                    ? "合约地址" : "合約地址",
            text: contractAddress,
            isAddress: true,
            bg: "primary",
        },
        {
            title: nameText,
            text: name,
            bg: "primary",
        },
        {
            title: symbolText,
            text: symbol,
            bg: "primary",
        },
        {
            title: supplyText,
            text: supply,
            bg: "primary",
        },
        {
            title: decimalText,
            text: decimal,
            bg: "primary",
        },
        {
            title: moduleText,
            text: moduleNum,
            bg: "primary",
        },
        {
            title: "DEX",
            text: "PancakeSwap",
            bg: "primary",
        },
        {
            title: "Currency",
            text: "BNB",
            bg: "primary",
        },
    ]

    const buyTax = moduleNum >= 2
        ? <span> Marketing : {buyMarketing}% <br />
            Liquidity : {buyLiquidity}% <br />
            Burn : {buyBurn}% <br />
            Reward : Reward %
        </span>
        : <span> Marketing : {buyMarketing}% <br />
            Liquidity : {buyLiquidity}% <br />
            Burn : {buyBurn}% <br />
        </span>

    const sellTax = moduleNum >= 2
        ? <span> Marketing : {sellMarketing}% <br />
            Liquidity : {sellLiquidity}% <br />
            Burn : {sellBurn}% <br />
            Reward : Reward %
        </span>
        : <span> Marketing : {sellMarketing}% <br />
            Liquidity : {sellLiquidity}% <br />
            Burn : {sellBurn}% <br />
        </span>

    const taxInfo = [
        {
            title: "Buy Taxes",
            bg: "primary",
            description: buyTax,
            inputTitle:
                [
                    "Input new buy liquidity tax",
                    "Input new buy burn tax",
                    "Input new buy marketing tax",
                ],
            functionName: "Set Buy Taxes",
            function:
                [
                    (value) => setBuyLiquidity(value * 100),
                    (value) => setBuyBurn(value * 100),
                    (value) => setBuyMarketing(value * 100),
                ],
            asyncFunction:
                async (v1, v2, v3) => await contract.setFees(
                    [v1 * 100, v2 * 100, v3 * 100, sellLiquidity * 100, sellBurn * 100, sellMarketing * 100]
                ),
            // async (v1, v2, v3) => await contract.setFees([v1, v2, v3, sellLiquidity, sellBurn, sellMarketing]),
            functionHasInput: true,
        },
        {
            title: "Sell Taxes",
            bg: "primary",
            description: sellTax,
            inputTitle:
                [
                    "Input new sell liquidity tax",
                    "Input new sell burn tax",
                    "Input new sell marketing tax",
                ],
            functionName: "Set Sell Taxes",
            function:
                [
                    (value) => setSellLiquidity(value * 100),
                    (value) => setSellBurn(value * 100),
                    (value) => setSellMarketing(value * 100),
                ],
            asyncFunction:
                async (v1, v2, v3) => await contract.setFees(
                    [buyLiquidity * 100, buyBurn * 100, buyMarketing * 100, v1 * 100, v2 * 100, v3 * 100]
                ),
            // async (v1, v2, v3) => await contract.setFees([v1, v2, v3, sellLiquidity, sellBurn, sellMarketing]),
            functionHasInput: true,
        },
        {
            title: "Renounce Tax Right",
            bg: "primary",
            // description: "Giving up the right of changing tax",
            functionName: "Renounce Tax Right",
            function: (value) => setIsWhitelist(value),
            asyncFunction: async () => await contract.disableChangeTax(),
            functionHasInput: false,
            alert: "After using this function, you cannot change the tax anymore",
        },
    ]

    const ownershipInfo = [
        {
            title: "Owner",
            bg: "primary",
            description: owner,
            inputTitle: "Input new Owner",
            functionName: "Transfer Ownership",
            function: (value) => setOwner(value),
            asyncFunction: async (value) => await contract.transferOwnership(value),
            isAddress: true,
            functionHasInput: true,
        },
        {
            title: "Renounce Ownership",
            bg: "primary",
            // description: "Giving up the ownership",
            functionName: "Renounce Ownership",
            function: () => setOwner("0x000000000000000000000000000000000000dEaD"),
            asyncFunction: async () => await contract.renounceOwnership(),
            functionHasInput: false,
            alert: "After using this function, you cannot use the control panel anymore",
        },
    ]

    const marketingWalletInfo = [
        {
            title: "Marketing Wallet 1",
            bg: "primary",
            description: marketingWallet,
            inputTitle: "Input new marketing wallet 1",
            functionName: "Set Marketing Wallet 1",
            function: (value) => setMarketingWallet(value),
            asyncFunction: async (value) => await contract.setFundAddress(value),
            functionHasInput: true,
            isAddress: true,
        },
        {
            title: "Marketing Wallet 2",
            bg: "primary",
            description: marketingWallet2,
            inputTitle: "Input new marketing wallet 2",
            functionName: "Set Marketing Wallet 2",
            function: (value) => setMarketingWallet(value),
            asyncFunction: async (value) => await contract.setFundAddress2(value),
            functionHasInput: true,
            isAddress: true,
        },
        {
            title: "Marketing Ratio",
            bg: "primary",
            description:
                <span>Wallet 1 : ${fundRatio} % <br />
                    Wallet 2: ${100 - fundRatio} %
                </span>,
            inputTitle: "Input new marketing ratio(The ratio for marketing wallet 1)",
            functionName: "Set Marketing Wallet 1 Ratio",
            function: (value) => setFundRatio(value),
            asyncFunction: async (value) => await contract.setFundRatio(value),
            functionHasInput: true,
        },
    ]

    const whiteListInfo = [
        {
            title: "Add Whitelist",
            bg: "primary",
            // description: "Set address as whitelist",
            inputTitle: "Input new whitelist address",
            functionName: "Add Whitelist",
            function: (value) => setIsWhitelist(value),
            asyncFunction: async (value) => await contract.setFeeWhiteList([value], true),
            functionHasInput: true,
        },
        {
            title: "Remove Whitelist",
            bg: "primary",
            // description: "Remove address from whitelist",
            inputTitle: "Input the whitelist address you want to remove",
            functionName: "Remove Whitelist",
            function: (value) => setIsWhitelist(value),
            asyncFunction: async (value) => await contract.setFeeWhiteList([value], false),
            functionHasInput: true,
        },
        {
            title: "Check Whitelist",
            bg: "primary",
            // description: "Check if an address is in the whitelist",
            inputTitle: "Input the whitelist address you want to check",
            functionName: "Check Whitelist",
            function: (value) => setIsWhitelist(value),
            asyncFunction: async (value) => await contract._feeWhiteList(value),
            functionHasInput: true,
        },
    ]
    const blackListInfo = [
        {
            title: "Add Blacklist",
            bg: "primary",
            // description: "Set address as blacklist",
            inputTitle: "Input new blacklist address",
            functionName: "Add Blacklist",
            function: (value) => setIsWhitelist(value),
            asyncFunction: async (value) => await contract.multi_bclist([value], true),
            functionHasInput: true,
        },
        {
            title: "Remove Blacklist",
            bg: "primary",
            // description: "Remove address from blacklist",
            inputTitle: "Input the blacklist address you want to remove",
            functionName: "Remove Blacklist",
            function: (value) => setIsWhitelist(value),
            asyncFunction: async (value) => await contract.multi_bclist([value], false),
            functionHasInput: true,
        },
        {
            title: "Check Blacklist",
            bg: "primary",
            // description: "Check if an address is in the blacklist",
            inputTitle: "Input the blacklist address you want to check",
            functionName: "Check Blacklist",
            function: (value) => setIsWhitelist(value),
            asyncFunction: async (value) => await contract.isReward(value),
            functionHasInput: true,
        },
    ]

    const parseDecimal = (value) => {
        return ethers.utils.parseUnits(value, decimal)
    }

    const limitInfo = [
        {
            title: "Buying Limit",
            bg: "primary",
            description: maxBuyAmount,
            inputTitle: "Input new buying limit",
            functionName: "Set Buying Limit",
            function: (value) => setMaxBuyAmount(value),
            asyncFunction: async (value) => await contract.changeSwapLimit(
                parseDecimal(value), parseDecimal(maxSellAmount)),
            functionHasInput: true,
        },
        {
            title: "Selling Limit",
            bg: "primary",
            description: maxSellAmount,
            inputTitle: "Input new selling limit",
            functionName: "Set Selling Limit",
            function: (value) => setMaxSellAmount(value),
            asyncFunction: async (value) => await contract.changeSwapLimit(
                parseDecimal(maxBuyAmount), parseDecimal(value)),
            functionHasInput: true,
        },
        {
            title: "Wallet Limit",
            bg: "primary",
            description: maxWalletAmount,
            inputTitle: "Input new wallet limit",
            functionName: "Set Wallet Limit",
            function: (value) => setMaxWalletAmount(value),
            asyncFunction: async (value) => await contract.changeWalletLimit(parseDecimal(value)),
            functionHasInput: true,
        },
    ]

    const contractControl = [
        {
            title: "Ownership",
            content: ownershipInfo,
        },
        {
            title: "Taxes",
            content: taxInfo,
        },
        {
            title: "Marketing Wallet",
            content: marketingWalletInfo,
        },
        {
            title: "Whitelist",
            content: whiteListInfo,
        },
        {
            title: "Blacklist",
            content: blackListInfo,
        },
        {
            title: "Transaction Limitation",
            content: limitInfo,
        }
    ]

    return (
        <div className="h-80">
            {
                isLoading &&
                <Loading />
            }
            <h1>Token Control Panel</h1>
            <div className="row">

                {/* 合約信息 */}
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Contract Info</Card.Title>
                        </Card.Header>
                        {
                            basicInfo.map((data, index) => {
                                if (index === 0) return;
                                return (
                                    <Table responsive key={index}>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <span style={{ textAlign: 'left', }}>
                                                        <span
                                                            className="d-flex align-items-center max-width-100"
                                                            style={{ paddingLeft: '20px' }}>
                                                            {data.title}
                                                        </span>
                                                    </span>
                                                </th>
                                                <td className='scrollable-element' style={{ textAlign: 'right', overflowX: 'scroll' }}>
                                                    {
                                                        (data.isAddress == undefined || data.isAddress == null) &&
                                                        <span>{data.text}</span>
                                                    }
                                                    {
                                                        (data.isAddress !== undefined && data.isAddress !== null && data.text !== null) &&
                                                        <span>
                                                            {data.text.slice(0, 4)}...{data.text.slice(-4)}
                                                            <CopyToClipboard
                                                                text={data.text}
                                                                onCopy={() => setCopied(true)}
                                                            >
                                                                <i
                                                                    className="fa-solid fa-copy"
                                                                    style={{ paddingLeft: '10px' }}
                                                                    onClick={() => {
                                                                        swal('Success', `Copy ${data.text} Success`, 'success')
                                                                    }}>
                                                                </i>
                                                            </CopyToClipboard>
                                                        </span>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                )
                            })
                        }
                    </Card>
                </Col>
                {/* 合約信息 */}


                {/* 合約控制台 */}
                <Col lg={8}>

                    <Card>
                        <Card.Header>
                            <Card.Title>Control Panel</Card.Title>

                            <Card.Subtitle>
                                <div className="input-group d-flex flex-wrap">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            className="form-control btn btn-primary btn-outline-primary"
                                            style={{ zIndex: 0, minWidth: '220px', }}
                                        >
                                            {inFunction === null
                                                ? "選擇功能"
                                                : contractControl[inFunction].title}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="end">
                                            {
                                                contractControl.map((moduleData, index) => {

                                                    //  0 稅模板 只有代幣所有權
                                                    if (moduleNum == 0)
                                                        if (index !== 0) return;
                                                    return (
                                                        <div
                                                            key={index}
                                                            className='d-flex align-items-center'
                                                            style={{ padding: '5px', minWidth: '220px' }}
                                                            onClick={() => setInFunction(index)}
                                                        >
                                                            <Dropdown.Item >
                                                                <div>{moduleData.title}</div>
                                                            </Dropdown.Item>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Card.Subtitle>
                        </Card.Header>
                        {
                            contractControl.map((data, index) => {
                                if (index !== inFunction) return;
                                return (
                                    <Table responsive key={index}>
                                        <tbody>
                                            <th>
                                                <h3 style={{ textAlign: 'left', }}>
                                                    <span
                                                        className="d-flex align-items-center max-width-100"
                                                        style={{ paddingLeft: '20px', }}>
                                                        {data.title}
                                                    </span>
                                                </h3>
                                            </th>
                                            {
                                                data.content.map((d, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th>
                                                                <span style={{ textAlign: 'left', }}>
                                                                    <span
                                                                        className="d-flex align-items-center"
                                                                        style={{ paddingLeft: '40px', maxWidth: '120px' }}>
                                                                        {d.title}
                                                                    </span>
                                                                </span>
                                                            </th>
                                                            <td className='scrollable-element' style={{
                                                                textAlign: 'right',
                                                                overflowX: 'scroll',
                                                                // whiteSpace: 'pre-wrap', // this property allows line breaks to occur at 'normal' word break points
                                                                maxWidth: '50vw',
                                                                wordBreak: 'break-word' // this property breaks words that are too long to fit in one line
                                                            }}>
                                                                {
                                                                    (d.isAddress == undefined || d.isAddress == null) &&
                                                                    <span>{d.description}</span>
                                                                }
                                                                {
                                                                    (d.isAddress !== undefined && d.isAddress !== null && d.description !== null) &&
                                                                    <span>
                                                                        {d.description.slice(0, 4)}...{d.description.slice(-4)}
                                                                        <CopyToClipboard
                                                                            text={d.description}
                                                                            onCopy={() => setCopied(true)}
                                                                        >
                                                                            <i
                                                                                className="fa-solid fa-copy"
                                                                                style={{ paddingLeft: '10px' }}
                                                                                onClick={() => {
                                                                                    swal('Success', `Copy ${d.description} Success`, 'success')
                                                                                }}>
                                                                            </i>
                                                                        </CopyToClipboard>
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td>
                                                                <Button className="me-2" variant="primary"
                                                                    onClick={() => {
                                                                        setModalWithTooltip(true)
                                                                        setModifying([d.functionName, d.inputTitle, d.function, d.asyncFunction, d.functionHasInput, d.alert]);
                                                                    }}>
                                                                    <i className="fa fa-pencil-square"></i>
                                                                    {" "}Edit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                )
                            })
                        }
                    </Card>
                </Col>
                {/* 合約控制台 */}

            </div>
            <MyModal
                showModal={modalWithTooltip}
                setModalWithTooltip={setModalWithTooltip}
                modifying={modifying}
                setErrorText={setErrorText}
            />
            <div
                style={{
                    display: 'flex',
                    zIndex: 9999,
                    top: '10vh',
                    right: '5vw',
                    position: 'fixed',
                    maxWidth: '60vw'
                }}>
                {
                    errorText !== null &&
                    <MyAlert
                        errorText={errorText}
                        setErrorText={setErrorText}
                    />
                }
            </div>
        </div >
    );
};

export default ControlPanel;



export const MyModal = ({ showModal, setModalWithTooltip, modifying, setErrorText }) => {
    const [tempArray, setTempArray] = useState([])
    const [tempSum, setTempSum] = useState(0);
    const [tempTrue, setTempTrue] = useState(null);
    if (modifying[2] === undefined) return;

    const closeModal = () => {
        setModalWithTooltip(false)
        setTempSum(0)
        setTempArray([])
        setTempTrue(null);
    }

    const setTempChange = (num, value) => {
        //  得到輸入的稅率
        const defaultArray = Array.from(
            { length: modifying[2].length },
            (_, index) => index === num ? value : 0
        );
        //  設定稅率陣列
        setTempArray(prevTempArray => {
            const newValue = defaultArray[num] ?? prevTempArray[num];

            //  加總
            setSum([
                ...prevTempArray.slice(0, num),
                newValue,
                ...prevTempArray.slice(num + 1)
            ])
            return [
                ...prevTempArray.slice(0, num),
                newValue,
                ...prevTempArray.slice(num + 1)
            ];
        });
    }

    const setSum = (props) => {
        const tempArraySum = props.reduce(
            (accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);

        setTempSum(tempArraySum)
    }

    return (
        <Modal className="fade" show={showModal} style={{ marginTop: '25vh' }}>
            <Modal.Header>
                <Modal.Title>{modifying[0]}</Modal.Title>
                <Button variant="" className="btn-close" onClick={closeModal} />
            </Modal.Header>
            <Modal.Body>
                {
                    !modifying[4] &&
                    <>
                        <h5>{modifying[5]}</h5>
                    </>
                }
                {
                    modifying[4] && (modifying[2].length == 1) &&
                    <>
                        <h5>{modifying[1]}</h5>
                        <input className='form-control' id='input' />
                        {
                            tempTrue != null
                            &&
                            <span>
                                {
                                    tempTrue
                                        ? "True" : "False"
                                }
                            </span>
                        }
                    </>
                }
                {
                    modifying[4] && modifying[2].length != 1 &&
                    <>
                        {
                            modifying[1].map((data, index) => {
                                const length = modifying[1].length;
                                return (
                                    <>
                                        <h5 key={data}>{data}</h5>
                                        <input className='form-control multiInput' onChange={(e) => setTempChange(index, e.target.value)} defaultValue='0' />
                                        {
                                            index === length - 1 &&
                                            <span>{tempSum}</span>
                                        }
                                    </>
                                )
                            })
                        }
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger light" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={
                    async () => {
                        try {
                            //  如果是有input的 將值傳入 調用function
                            if (modifying[4]) {

                                //  如果只有一個input

                                if (modifying[2].length == 1) {
                                    try {
                                        let inputArea = document.getElementById('input')
                                        let value = inputArea.value
                                        let result = await modifying[3](value)
                                        console.log(result);
                                        modifying[2](value);

                                        if (result === true) setTempTrue(true)
                                        if (result === false) setTempTrue(false)
                                    } catch (err) {
                                        if (err.data === undefined)
                                            setErrorText(err.message)
                                        else
                                            setErrorText(err.data.message)
                                    }
                                }

                                //  如果有好多個input
                                if (modifying[2].length != 1) {
                                    try {
                                        //  得到新的稅率
                                        const inputs = Array.from(document.querySelectorAll('.multiInput'));
                                        const inputData = inputs.map(input => input.value);
                                        console.log(inputData);

                                        //  傳入input的數值
                                        let result = await modifying[3](...inputData)
                                        console.log(result);
                                    } catch (err) {
                                        if (err.data === undefined)
                                            setErrorText(err.message)
                                        else
                                            setErrorText(err.data.message)
                                    }
                                }
                            }
                            //  如果是沒有input的 直接調用function
                            if (!modifying[4])
                                try {
                                    let result = await modifying[3]()
                                    console.log(result)
                                    modifying[2]()
                                } catch (err) {
                                    if (err.data === undefined)
                                        setErrorText(err.message)
                                    else
                                        setErrorText(err.data.message)
                                }
                        } catch (err) {
                            if (err.data === undefined)
                                setErrorText(err.message)
                            else
                                setErrorText(err.data.message)
                        }
                    }
                }>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal >
    );
};