import recorderABI from '../ABI/RecorderABI.json'
import creatorABI from '../ABI/contractABI.json'

export const ABIList = {
    recorderABI: recorderABI,
    creatorABI: creatorABI
}

export const CAList = {
    bsc:{
        contractCreator: '0x224093f2D785250627d14f0bBf8C5c288E2F501c',
        contractRecorder: '0xbEFaE761F84b21AB017BD320Ec5Ee8E11cC05Ad3'
    },
}

